<!-- =========================================================================================
  File Name: FullPage.vue
  Description: Full page layout
  ----------------------------------------------------------------------------------------
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="layout--full-page">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created () {
    localStorage.setItem('locale', this.$route.params.lang)
    if (this.$route.params.lang === 'en' || this.$cookies.get('language') === 'en') {
      this.$i18n.locale = 'en'
      this.$vs.rtl = false
    } else {
      this.$i18n.locale = 'ar'
      this.$vs.rtl = true
    }
  }
}
</script>

<style>
[dir=rtl] .vs-input--input.normal {
  padding: 5.6px !important;
}
iframe[title="Message from company"],
iframe[title="Close message"] {
  display: none !important;
}
#launcher {
  display: none;
}
</style>
